<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle text="公司简介" />
      <div class="textBox">
        <img src="../../assets/img/logo/commpany.png" alt="">
        <div class="text">
            北京瑜璟缘国际文化发展有限公司是一所集形象管理培训（设计）、礼仪文化培训及策划运营管理为一体的专注于孕育及培养提升人才素养的专业培训机构。在全国拥有近200人的专业的签约礼仪咨询顾问和专家队伍，专注于为企业提供系统礼仪管理咨询与业务解决方案，为企业搭建高素质人才培养平台，为企业形象及人素养教育提升开辟有效途径，帮助企业完善礼仪文化建设，是国内多家企事业单位背后最有效的形象生产及教育培训基地。公司以“不学礼，无以立。”的宗旨，秉承“无问题，不培训”的运营理念，致力于打造新经济下交流分享、提升自我，创新升级，共享共赢生态圈的高端商业精英交流平台。专注于为企业家、创业者、企业高管提供创新升级的商业课程，高效落地的企业内训，垂直定向的主题沙龙，拓展视野的海外游学以及资源整合等培训咨询共享共赢服务解决方案。
        </div>
      </div>
    </div>
    <sidebar/>
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar"
export default {
  components: {
    textTitle,
    sidebar,
  },
};
</script>
<style lang="scss" scoped>
.leftBox {

  .textBox {
    width: 80%;
    margin-left: 10%;
    .text {
      text-align: left;line-height: 26px;
      text-indent: 2em;
      margin-top: 20px;
    }
    img{
      display: block;
      margin: 0 auto;
    }
    .wire{
        border: rgb(239, 233, 233) 1px solid;
        margin: 10px 0;
    }
  }
}

</style>